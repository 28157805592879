export default function calcPromoDiscount (args) {
  // console.log({shopId, products})
  try {
    const {
      shopId, cartType,
      products, promoCode='', totalPrice, taxTotal
    } = args

    let totalDiscount = 0

    // if (shopId === 'tanghuo') {
    //   if (['foodaddictsla', 'tanghuo10'].indexOf((promoCode || '').toLowerCase()) !== -1) {
    //     totalDiscount += Math.round(totalPrice * 0.10 * 100) / 100
    //   }
    //   // Monday Discount Special
    //   if (['mala10'].indexOf((promoCode || '').toLowerCase()) !== -1) {
    //     totalDiscount += Math.round(totalPrice * 0.15 * 100) / 100
    //     let hasDrink = false
    //     products.forEach(({sub}) => {
    //       if (sub.indexOf('Drinks') !== -1) { // is drink
    //         hasDrink = true
    //       }
    //     })
    //     if (hasDrink) {
    //       totalDiscount += 1.25
    //     }
    //   }
    // }
    if (shopId === 'lancers') {
      if (['xy78ae'].indexOf((promoCode || '').toLowerCase()) !== -1) {
        totalDiscount += Math.min(25, (totalPrice+taxTotal))
      }
    }

    if (shopId === 'themelt') {
      if (['nohotchicken'].indexOf((promoCode || '').toLowerCase()) !== -1) {
        totalDiscount += Math.round(totalPrice * 0.10 * 100) / 100
      }
    }

    if (shopId === 'beachwood') {
      if (['llama','bestpizza'].indexOf((promoCode || '').toLowerCase()) !== -1) {
        let hasPromo, hasPie
        products.forEach(({sub}) => {
          if (sub.indexOf('Promo') !== -1) { // is drink
            hasPromo = true
          }
          if (sub.indexOf('House Pie') !== -1) { // is drink
            hasPie = true
          }
        })
        if (hasPie) {totalDiscount += 0.1}

        if (hasPromo && hasPie) {
          totalDiscount = 13
        }
      }
    }
    // if (shopId === 'cravesunset') {
    //   const numOfDinner = 0
    //   const numOfDessert = 0
    //   products.forEach(({sub}) => {
    //     if (sub.indexOf('Dinner') !== -1) { // is drink
    //       numOfDinner++
    //     }
    //     if (sub.indexOf('Dessert') !== -1) { // is drink
    //       numOfDessert++
    //     }
    //   })
    //   if (hasPie) {totalDiscount += 0.1}

    //   if (hasPromo && hasPie) {
    //     totalDiscount = 13
    //   }
    // }

    if (shopId === 'inthemix') {
      if (['mix30off'].indexOf((promoCode || '').toLowerCase()) !== -1) {
        totalDiscount += Math.round(totalPrice * 0.3 * 100) / 100
      }
    }
    if (shopId === 'muiishi') {
      if (['muiishi7'].indexOf((promoCode || '').toLowerCase()) !== -1) {
        totalDiscount += Math.round(totalPrice * 0.1 * 100) / 100
      }
    }

    if (shopId === 'kluckinsd') {
      const now = new Date()
      const day = now.getDay() // Sunday - Saturday : 0 - 6
      const hours = now.getHours() //0 to 23
      const minutes = now.getMinutes() // 0 to 59
      console.log(promoCode, day, hours)
      if (['getklucked'].indexOf((promoCode || '').toLowerCase()) !== -1) {
        if ([0,1,21,22,23].indexOf(hours) !== -1) {
          totalDiscount += Math.round(totalPrice * 0.15 * 100) / 100
        }
      }
      if (['kluckinlunch'].indexOf((promoCode || '').toLowerCase()) !== -1) {
        if ([12,13].indexOf(hours) !== -1) {
          totalDiscount += Math.round(totalPrice * 0.15 * 100) / 100
        }
        if ((hours === 11) && (minutes > 29)) {
          totalDiscount += Math.round(totalPrice * 0.15 * 100) / 100
        }
      }
    }

    // if (shopId === 'pablitostacosburbank') {
    //   if (['rain'].indexOf((promoCode || '').toLowerCase()) !== -1) {
    //     totalDiscount += Math.round(totalPrice * 0.2 * 100) / 100
    //   }
    // }




    // if (shopId === 'pablitostacosburbank') {
    //   if (['janequengua3'].indexOf((promoCode || '').toLowerCase()) !== -1) {
    //     totalDiscount += Math.min(9, (totalPrice + taxTotal))
    //   }
    // // }
    // if (shopId === 'pablitostacosnoho') {
    //   if (['pablitospizza468'].indexOf((promoCode || '').toLowerCase()) !== -1) {
    //     totalDiscount += Math.min(10, (totalPrice + taxTotal))
    //   }
    // }

    // if (shopId === 'pablitostacosburbank') {
    //   // cinco
    //   let numRegularTacos = 0
    //   let numCrispyTacos = 0
    //   products.forEach(({name, sub, price, quantity=1}) => {
    //     if (name && name.en === 'Regular Taco*') { // is street tacos
    //       numRegularTacos += quantity
    //     } 
    //     if (name && name.en === ('Crispy Beef Taco*')) { // is street tacos
    //       numCrispyTacos += quantity
    //     } 
    //   })
    //   // console.log('🌮', numRegularTacos)
      
    //   totalDiscount = totalDiscount + Math.min(10, numRegularTacos) * (3.25 - 0.5)
    //   totalDiscount = totalDiscount + Math.min(10, numCrispyTacos) * (2.99 - 0.5)
      
    // }

    if (shopId === 'mikaza') {
      if (['nikkei'].indexOf((promoCode || '').toLowerCase()) !== -1) {
        totalDiscount += Math.round(totalPrice * 0.15 * 100) / 100
      }
    }
    if (shopId === 'mikaza') {
      if (['thursday'].indexOf((promoCode || '').toLowerCase()) !== -1) {
        totalDiscount += Math.round(totalPrice * 0.1 * 100) / 100
      }
    }
    // if (shopId === 'pablitostacosoaks') {
    //   if (['pablitos20'].indexOf((promoCode || '').toLowerCase()) !== -1) {
    //     totalDiscount += Math.round(totalPrice * 0.2 * 100) / 100
    //   }
    // }
    // if (shopId === 'cravesunset') {
    //   if (['xy52j9'].indexOf((promoCode || '').toLowerCase()) !== -1) {
    //     totalDiscount += Math.min(20, (totalPrice + taxTotal))
    //   }
    // }
    if (shopId === 'cravestudiocity') {
      if (['lovemom', 'mom9031'].indexOf((promoCode || '').toLowerCase()) !== -1) {
        totalDiscount += Math.min(10, (totalPrice + taxTotal))
      }
    }

    if (shopId === 'pablitostacosnoho') {
      if (['pablitos2022'].indexOf((promoCode || '').toLowerCase()) !== -1) {
        products.forEach((product) => {
          const {sub, price, quantity, tag} = product
          console.log(product)
          if (sub.indexOf('Pizza') !== -1 || sub.indexOf('Wings') !== -1) { // is wine
            for (let step = 0; step < quantity; step++) {
              totalDiscount += price * 0.2
            }
          }
        })
        
      }
    }

    if ((shopId === 'cravesunset-')) {
      const now = new Date()
      const dayDigit = now.getDay()
      if ([0,6].indexOf(dayDigit) !== -1) {
        let breakfastCategories = ['Breakfast', 'Eggs', 'Bagels', 'Paninis', 'Crepes', 'Pancakes']  
        let breafastPrices = []
        products.forEach((product) => {
          const {sub, price, quantity, tag} = product
          console.log(product)
          if (sub.indexOf('Breakfast Special') !== -1) { // is wine
            for (let step = 0; step < quantity; step++) {
              breafastPrices.push(price)
            }
          }
        })
        console.log("🍷" + breafastPrices)
        const sorted = breafastPrices.sort((a, b) => -(a - b))
        let discounts = 0
        const numOfFreeWine = Math.floor(sorted.length / 2)
        for (let step = 0; step < numOfFreeWine; step++) {
          // set a wine to free
          // remove highest priced item
          const expensive = sorted.shift()
          const cheap     = sorted.shift()
          discounts+=cheap
        }
        totalDiscount += discounts
      }
    } else {
      // return 0
    }

    // if (shopId === 'muiishi') {
    //   let hasChicken = false
    //   let totalPrice = 0
    //   products.forEach(({name, sub, price, quantity=1}) => {
    //     if (name && name.en.indexOf('Gyoza') !== -1) { // is wine
    //       hasChicken = true
    //     } else {
    //       totalPrice = price * quantity + totalPrice
    //     }
    //   })
      

    //   if ((totalPrice > 10) && (hasChicken)) {
    //     totalDiscount += Number(5.99)
    //   }

    // }

    if (shopId === 'shyo') {
      let numOfBrussels = 0
      let numOfRamen = 0
      const now = new Date()
      const day = now.getDay() // Sunday - Saturday : 0 - 6
      if (day !== 6) {return}
      products.forEach(({name, sub, price, quantity=1}) => {
        if (name && name.en.indexOf('Brussels') !== -1) { // is wine
          numOfBrussels = numOfBrussels + quantity
        } else if (sub.indexOf('Ramen') !== -1) { // is wine
          numOfRamen = numOfRamen + quantity
        } 
      })
      console.log({numOfBrussels, numOfRamen})
      
      const maxBDiscount = Math.floor(numOfRamen / 2)
      const numOfBDiscount = Math.min(maxBDiscount, numOfBrussels)
      totalDiscount += Number(numOfBDiscount * 4)
    }

    if (shopId === 'chens') {
      let hasDonut = false
      let totalPrice = 0
      products.forEach(({sub, name, price, quantity=1}) => {
        if (name && (name.en.indexOf('Chinese Donut') !== -1)) { // is wine
          hasDonut = true
        } else {
          totalPrice = price * quantity + totalPrice
        }
      })
      
      // console.log("😫" + totalPrice)

      if ((totalPrice > 36.6) && (hasDonut)) {
        totalDiscount += Number(3.29)
      }

    }

    if (shopId === 'bierhaus') {
      let hasFlatBread = false
      let count = 0
      products.forEach(({sub, price, quantity}) => {
        if (sub.indexOf('Small Plates') !== -1) { // is wine
          for (let step = 0; step < quantity; step++) {
            count++
          }
        }
        if (((promoCode || '').toLowerCase() === 'flatbread') && (sub.indexOf('Flatbreads') !== -1)) { // is wine
          hasFlatBread = price
        }
      })
      let discounts = Math.floor(count / 3) * 2
      totalDiscount += discounts

      if ((totalPrice >= 30) && (hasFlatBread)) {
        totalDiscount += Number(hasFlatBread)
      }

      if (['kitchen', 'eat', 'dad', 'dad15', 'nomess', 'noprep', 'community', 'nodriver'].indexOf((promoCode || '').toLowerCase()) !== -1) {
        totalDiscount += Math.round(totalPrice * 0.15 * 100) / 100
      }

    }


    return Math.round(totalDiscount * 100) / 100
  } catch (e) {
    return 0
  }


}
