
import * as Components from "./Larryschilidog"

export default function getCustomComponent () {
  return Components
}

export function getShopId () {
  return "larryschilidog"
}

