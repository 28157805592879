import React, { Component } from 'react';

import './index.scss'

class Pablitos extends Component {
  render() {
    return (
      <div className="pablitos">
      </div>
    );
  }
}

export class Header extends Component {
  render() {
    return (
      <div className="order-benefits-wrapper">
        <div className="order-benefits">

          <div className="text">
            <p>Order Ahead below⤵︎ </p>

          </div>


        </div>
      </div>
    );
  }
}

export default Pablitos;
